<template>
  <div class="view pa24">
    <div class="d-flex">
<!--      <div style="display: flex">-->
<!--        <el-select-->
<!--            v-model="kjMembers"-->
<!--            style="width: 93%;margin-right: 15px"-->
<!--            placeholder="可见会员"-->
<!--        >-->
<!--          <el-option-->
<!--              v-for="(item) in list"-->
<!--              :key="item.id"-->
<!--              :label="item.name"-->
<!--              :value="item.id"-->
<!--          />-->
<!--        </el-select>-->
<!--        <el-button type="primary" @click="filterNames">搜索</el-button>-->
<!--      </div>-->
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="centerDialogVisible = true,bdetermine = true,editUpdate = false"
        >+ 添加公告</el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column align="center" type="index" label="ID"/>
        <el-table-column prop="title" align="center" label="公告标题" show-overflow-tooltip/>
        <el-table-column prop="visibleType" align="center" label="可见成员" show-overflow-tooltip/>
        <el-table-column prop="createTime" align="center" label="添加时间" show-overflow-tooltip/>
        <el-table-column align="center" label="置顶" width="200">
          <template slot-scope="scope">
            <el-switch
              @change="changeIstop(scope.row)"
              v-model="scope.row.isTop"
            >
          </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
<!--            <el-button-->
<!--                type="text"-->
<!--                @click="-->
<!--                edit(scope.row, false);-->
<!--                bEdit = true;-->
<!--              "-->
<!--            >详情-->
<!--            </el-button-->
<!--            >-->
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑
            </el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
                confirm-button-text="确定"
                k cancel-button-text="取消"
                @confirm="del(scope.row.orgNoticeId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除
              </el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加公告"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="78px"
      >
        <el-form-item label="公告标题" prop="title" label-width="130px">
          <el-input clearable v-model="ruleForm.title" maxlength="21" show-word-limit></el-input>
        </el-form-item>
<!--        <el-form-item label="简述" prop="sketch"  label-width="130px">-->
<!--          <el-input  type="textarea" v-model="ruleForm.sketch" maxlength="200" show-word-limit></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="公告详情" label-width="130px" prop="details">
          <!-- //富文本 -->
          <quill-editor
              style="height: 100%"
              v-model="ruleForm.details"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{TiLength}}/10000</span>
        </el-form-item>
        <el-form-item label="可见成员" label-width="130px" prop="isDistribution">
          <el-radio-group v-model="ruleForm.isDistribution">
            <el-radio :label="0">所有人</el-radio>
            <el-radio :label="1">所有会员</el-radio>
            <el-radio :label="2">指定会员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.isDistribution == 2">
          <el-select
              multiple
              v-model="ruleForm.tjMembers"
              style="width: 93%;margin-left: 53px"
              placeholder="请选择会员"
          >
            <el-option
                v-for="(item) in memberList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="会员模板推送" label-width="130px" prop="members">
          <el-switch
              v-model="ruleForm.members"
              active-color="#51CDCB"
              inactive-color="#b8b8b8">
          </el-switch>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写内容'
import commonTable from "@/components/common/commonTable";
import {mapState} from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {addOrgNotice, selectOrgNoticeList, upOrgNotice, delOrgNotice} from "@/api/content";
import {selectMemberPageList} from "@/api/members";

export default {
  name: 'announcementList',
  data() {
    return {
      kjMembers:'',
      list:[
        {id:0,name:'所有人'},
        {id:1,name:'所有会员'},
        {id:2,name:'指定会员'},
      ],
      TiLength:0,
      editorOption: quillConfig,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate: true,
      isUpdate: true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        details: "",
        isDistribution: 0,
        // members: true,
        sketch:"",
        tjMembers:[],
      },
      rules: {
        title: [{required: true, message: "请输入公告标题", trigger: "blur"}],
        // sketch: [{required: true, message: "请输入简述", trigger: "blur"}],
        details: [{required: true, message: "请输入公告详情", trigger: "blur"}],
        isDistribution: [{required: true, message: "请选择可见成员", trigger: "blur"}],
        // members: [{required: true, message: "请选择会员推送模板", trigger: "blur"}],
      },
      jurisdictionA: '',
      jurisdictionB: '',
      orgNoticeId:'',
      memberList:[]
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {

  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch: {
    routerArr(val) {
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if (this.jurisdictionA || this.jurisdictionB) {
        this.queryPage()
      }
    }
  },
  mounted() {
    this.queryPage();
    this.queryMemberList();
  },
  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(14000,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    async queryMemberList() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        examineStatus:0,
        ifBind:0,
      };
      try {
        const result = await selectMemberPageList(data);
        const { total, list } = result.data.pageInfo;
        this.memberList = list;
      } catch (error) {
        this.memberList = [];
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectOrgNoticeList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          if (list[i].visibleType == 0) {
            list[i].visibleType = '所有人'
          } else if (list[i].visibleType == 1) {
            list[i].visibleType = '所有会员'
          } else if (list[i].visibleType == 2) {
            list[i].visibleType = '指定会员'
          }
          list[i].createTime = this.renderTime(list[i].createTime)
          list[i].isTop = list[i].isTop == 1 ? true : false;
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    filterNames(){
      this.currentPage = 1;
      let data = {
        isDistribution:this.kjMembers
      };
      this.queryPage(data);
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      console.log(data)
      this.centerDialogVisible = true;
      this.ruleForm.title = data.title;
      this.ruleForm.details = data.content;
      this.ruleForm.sketch = data.sketch;
      if (data.visibleType == '所有人') {
        this.ruleForm.isDistribution = 0
      } else if (data.visibleType == '所有会员') {
        this.ruleForm.isDistribution = 1
      }else if (data.visibleType == '指定会员') {
        this.ruleForm.isDistribution = 2
      }
      this.orgNoticeId = data.orgNoticeId;
      // this.ruleForm.members = data.ifSend==0?true:false;
      this.ruleForm.tjMembers = JSON.parse(data.memberUsers);
      this.bdetermine = b;
      // this.bEdit = b;
    }
    ,
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              title: this.ruleForm.title,
              content: this.ruleForm.details,
              sketch: this.ruleForm.sketch,
              visibleType: this.ruleForm.isDistribution,
              // ifSend: this.ruleForm.members?0:1,
              memberUsers:JSON.stringify(this.ruleForm.tjMembers)
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.orgNoticeId = this.orgNoticeId
              await upOrgNotice(data);
            } else {
              await addOrgNotice(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
    ,
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delOrgNotice({orgNoticeId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        details: "", //商品详情
        isDistribution: "",
        members: true
      }),
          this.centerDialogVisible = false;
    },
    async changeIstop(row){
      let isTop = row.isTop ? 1 : 0;
      let opts = {
        associationId: localStorage.getItem("associationId"),
        isTop,
        orgNoticeId: row.orgNoticeId
      };
      try{
        this.$message({
          message: "正在保存",
        });
        await upOrgNotice(opts);
        await this.queryPage();
        this.$message({
          message: "保存成功",
          type: "success",
        });
      }catch{
        this.$message({
          message: error.message,
          type: "error",
        });
      }
    },
  },
}
;
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}

/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>




